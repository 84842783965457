import React, { useRef, useEffect } from 'react'
import Layout from './hero.views'
import lottie from 'lottie-web'
import workStation from 'images/work-station'

const Hero = () => {
  const animationContainer = useRef()

  useEffect(() => {    
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: workStation
    })
    lottie.setSpeed(.5)
    return () => anim.destroy()
  }, [])

  return (<Layout.Container>
  <Layout>
    <Layout.HeroContainer>
      <Layout.Animation ref={animationContainer} />
      <Layout.TextContainer>
        <Layout.HeroText>Noah Wilson</Layout.HeroText>
        <Layout.SubtitleText>Senior Software Engineer</Layout.SubtitleText>
        </Layout.TextContainer>
    </Layout.HeroContainer>
  </Layout></Layout.Container>)
}

export default Hero