import React from 'react'
import Layout from './navigation.views'

const Navigation = () => {
  return (
    <Layout>
      <Layout.NavItem>Home</Layout.NavItem>
      <Layout.NavItem>Projects</Layout.NavItem>
      <Layout.NavItem>Experience</Layout.NavItem>
      <Layout.NavItem>Contact</Layout.NavItem>
    </Layout>
  )
}

export default Navigation
