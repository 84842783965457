import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import { theme } from 'helpers'

const Projects = styled.div`
  background-color: ${theme.palette.darkest};
  padding: ${theme.spacing(20)} 0;
`
Projects.Container = styled(Container)`
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${theme.spacing(10)};
  ${theme.breakpoints.md} {
    grid-template-columns: 1fr;
  }
`

export default Projects
