import styled, { keyframes } from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { theme } from 'helpers'
import HeroBackground from 'images/hero-background-2.jpg'

const TitleAnimation = keyframes`
 0% { transform: translateX(-100%); opacity: 0; }
 100% { transform: translateX(0%); opacity: 1; }
`
const BackgroundAnimation = keyframes`
0% { transform: rotate(20deg) translate(-400px, -300px);}
100% { transform: rotate(20deg) translate(-800px, -300px); }
`

const Hero = styled.div`
  background-color: rgba(42, 56, 66, 1);
  height: 400px;
  padding: 50px;
  padding-top: 150px;
  overflow: hidden;
  mix-blend-mode: darken;
  &:before {
    content: '';
    width: 200%;
    height: 400%;
    opacity: .1;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${HeroBackground});
    background-size: 400px;
    animation: ${BackgroundAnimation} 20s infinite linear;
    
  }
`

Hero.Container = styled.div`
position: relative;
  overflow: hidden;
`

Hero.Animation = styled.div`
  max-width: 400px;
`

Hero.TextContainer = styled.div`
  margin-left: 100px;
  animation: ${TitleAnimation} 1s;
  color: ${theme.palette.white};
`

Hero.HeroContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

Hero.HeroText = styled(Typography).attrs({ variant: 'h2' })``
Hero.SubtitleText = styled(Typography).attrs({ variant: 'h6' })``

export default Hero
