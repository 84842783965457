export const theme = {
  spacing: (space) => {
    return `${space * 4}px`
  },
  palette: {
    primary: '#657786',
    secondary: '#FB6629',
    dark: '#2A3842',
    darkest: '#13151C',
    light: '#3C4D56',
    white: '#e9edf0'
  },
  breakpoints: {
    sm: '@media screen and (max-width: 600px)',
    md: '@media screen and (max-width: 800px)',
    lg: '@media screen and (max-width: 1000px)',
    xl: '@media screen and (max-width: 1200px)'
  }
}
