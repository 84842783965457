import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { theme } from 'helpers'

const ProjectCard = styled.div`
  background-color: ${theme.palette.dark};
  border-radius: 8px;
  overflow: hidden;
  color: ${theme.palette.white};
  position: relative;
  transform: scale(1);
  transition: all .6s;
  &:hover{
    cursor: pointer;
    transform: scale(1.02);
  }
`
export const HoverAnimation = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  &:before {
    content: '';
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.36458333333333337) 25%, rgba(255,255,255,0.38699229691876746) 30%, rgba(255,255,255,0) 38%, rgba(255,255,255,0) 47%, rgba(255,255,255,1) 55%, rgba(255,255,255,1) 59%, rgba(255,255,255,0) 77%, rgba(255,255,255,0.29175420168067223) 85%, rgba(255,255,255,0.19931722689075626) 92%, rgba(255,255,255,0) 100%);
    position: absolute;
    width: 200%;
    height: 200px;
    transform: translate(-500px, -300px) rotate(-40deg);
    opacity: .1;    
  }
  &:hover{
    &:before{
      transition: .4s linear;
      transform: translate(0px, 400px) rotate(-40deg);
    }
  }
  
`

ProjectCard.Image = styled.img`
  height: 200px;
  width: 100%;
  object-fit: cover;
`
ProjectCard.Container = styled.div`
  padding: ${theme.spacing(5)};
`
ProjectCard.Title = styled(Typography).attrs({ variant: 'h4' })``
ProjectCard.Content = styled(Typography)``
ProjectCard.Link = styled(Link)`
  display: block;
  color: inherit !important;
  font-family: sans-serif !important;
  padding: ${theme.spacing(5)} !important;
  border-top: 1px solid ${theme.palette.white};
  &:hover {
    text-decoration: none !important;
    opacity: .5;
  }
`

export default ProjectCard
