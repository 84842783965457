import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { theme } from 'helpers'

const Navigation = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: min-content;
  margin: 0 auto;
  height: 50px;
  border-radius: 25px;
  border: solid 2px ${theme.palette.white};
  z-index: 1000;
  top: ${theme.spacing(4)};
  transform: translateX(-50%);
  left: 50%;
`

Navigation.NavItem = styled(Button).attrs({ variant: 'text', color: 'default' })`
  margin: ${theme.spacing(4)} !important;
  border-radius: 20px !important;
  color: white !important;
  white-space: nowrap;
  transition: .2s all !important;
  &:hover{
    color: ${theme.palette.secondary} !important;
  }
`

export default Navigation