import React from 'react'
import { string } from 'prop-types'
import Layout, { HoverAnimation } from './project-card.views'

const ProjectCard = ({ image, title, content, linkText, link}) => {
  return (
    <Layout>
      <HoverAnimation />
      <Layout.Image src={image} />
      <Layout.Container>
        <Layout.Title>
          {title}
        </Layout.Title>
        <Layout.Content>
          {content}
        </Layout.Content>
      </Layout.Container>
      <Layout.Link href={link}>
        {linkText}
      </Layout.Link>
    </Layout>
  )
}

ProjectCard.propTypes = {
  image: string,
  title: string,
  content: string,
  linkText: string,
  link: string
}

export default ProjectCard
