import React from 'react'
import Layout from './projects.views.js'
import ProjectCard from 'components/project-card'

const Projects = () => {
  return (
    <Layout>
      <Layout.Container>
        <ProjectCard
          image='https://woocommerce.com/wp-content/uploads/2020/11/blog-fb-Creative-Mail@2x.jpg?resize=650,340'
          title='Creative Mail'
          content='I did a lot of work on this and I think it turned out really well, it was built with gatsby and react and it looks pretty decent and also has a decent amount of users'
          link='https://www.google.com'
          linkText='Check it out'
        />
        <ProjectCard
          image='https://www.creativemail.com/images/og-image.png'
          title='Creative Mail'
          content='I did a lot of work on this and I think it turned out really well, it was built with gatsby and react and it looks pretty decent and also has a decent amount of users'
          link='https://www.google.com'
          linkText='Check it out'
        />
        <ProjectCard
          image='https://www.creativemail.com/images/og-image.png'
          title='Creative Mail'
          content='I did a lot of work on this and I think it turned out really well, it was built with gatsby and react and it looks pretty decent and also has a decent amount of users'
          link='https://www.google.com'
          linkText='Check it out'
        />
        <ProjectCard />
        <ProjectCard />
      </Layout.Container>
    </Layout>
  )
}

export default Projects
