import React from 'react'
import Hero from 'components/hero'
import Layout from 'components/layout'
import Projects from 'components/projects'

const IndexPage = () => {
  return (<Layout>
    <Hero />
    <Projects />
  </Layout>)
}

export default IndexPage
