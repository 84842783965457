import React from 'react'
import Navigation from 'components/navigation'
import './global-styles.css'

const Layout = ({ children }) => {
  return (
    <div>
      <Navigation />
      {children}
    </div>
  )
}

export default Layout
